// main.ts
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import Vant from 'vant';

//引入全局字体样式
import '@/assets/font/font.css';
import 'vant/lib/index.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.use(Vant);
app.use(ElementPlus)
app.mount('#app');
