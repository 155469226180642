<template>
  <div class="productDetails">
    <div class="productDetailsTitle">
      <div>产品服务细节</div>
      <div>基于社交媒体运营服务一站式服务</div>
    </div>
    <div class="productDetailsContent">
      <img class="productDetailsContentImg" src="@/assets/image/productDetails.png" alt="">

    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style scoped lang="scss">
.productDetails{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;

  .productDetailsTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    >div:nth-of-type(1){
      font-weight: 600;
      font-size: 36px;
      color: #292929;
      margin-bottom: 20px;
    }
    >div:nth-of-type(2){
      font-weight: 400;
      font-size: 14px;
      color: #77849B;
    }
  }
  .productDetailsContent{
    display: flex;
    flex-direction: column;

    .productDetailsContentItem{
      width: 1140px;
      //height: 217px;
      background-color: #F6F9FF;
      padding: 20px 32px;
      box-sizing: border-box;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      .productDetailsContentItemLeft{
        width: 162px;
        height: auto;
        background-color: #c5eaff;
        box-shadow: 0 6px 15px 0 rgba(115,202,255,0.3);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 32px;
        color: #0049A8;
      }
      .productDetailsContentItemRight{
        width: 900px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        box-sizing: border-box;
        >div{
          width: 133px;
          height: 64px;
          background: #FFFFFF;
          box-shadow: 0px 2px 10px 0px rgba(203,210,214,0.14);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:nth-of-type(n + 7){
            margin-top: 20px;
          }
        }
      }
    }
  }
  .productDetailsContentImg{
    width: 1224px;
    margin-top: 60px;
  }
}
</style>
