<template>
<div class="topTypeListContainer">
  <div class="topTypeListTitle">
    <div class="topTypeListTitleIcon">
      <img src="@/assets/image/homeIcon.png" alt="">
    </div>
<!--    <div class="topTypeListTitleName">-->
<!--      阿猫智能-->
<!--    </div>-->
  </div>
  <div class="topTypeList">
    <div @click="showToast(index)" :class="index==0?'topTypeItem checkItem':'topTypeItem'" v-for="(item, index) in topTypeList" :key="index">
        {{item.name}}
    </div>
  </div>
</div>
  <div class="AIProductIntroduction">
    <div class="homeLogoBox">
      <div class="homeLogoBoxTitleOne">
        <div>AI智能体,</div>
        <div>大模型驱动的数字员工</div>
      </div>
      <div class="homeLogoBoxTitleTwo">
        <div>他可以是</div>
        <div>{{state.displayedText}}|</div>
<!--        <transition name="fade">-->
<!--          <span :key="currentText">{{ state.displayedText }}</span>-->
<!--        </transition>-->
      </div>
      <div class="homeButton" @click="goConsultingService">立即咨询</div>
      <div class="homeGifBox">
        <img src="@/assets/image/message.gif" />
      </div>
    </div>
    <workingPrinciple />
    <productDetails />
    <myCustomer />
    <footBox />
<!--    <foot />-->
  </div>
  <div class="onTrialButton" @click="goConsultingService">
    <div>
      <div>
        立即咨询
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import footBox from "@/components/footBox.vue"
import workingPrinciple from "@/components/workingPrinciple.vue"
import productDetails from "@/components/productDetails.vue";
import myCustomer from "@/components/myCustomer.vue"
import {onMounted, onUnmounted, ref, computed, reactive, nextTick} from 'vue'
import { ElMessage } from 'element-plus'
import router from "@/router";

let topTypeList = ref([
  {
    name: "AI产品介绍",
    value:0
  },
  {
    name: "解决方案",
    value:1
  },
  {
    name: "行业情报",
    value:2
  },
  {
    name: "关于我们",
    value:3
  },
])
const texts = ["智能客服", "智能销售", "数字员工","AI智能体"]; // 文字列表

// 使用 reactive 定义状态
const state = reactive<{
  displayedText: string;
  currentTextIndex: number;
  isIncreasing: boolean;
  waitTime:number
}>({
  displayedText: "", // 当前显示的文字
  currentTextIndex: 0, // 当前文字索引
  isIncreasing: true, // 是否正在增加文字
  waitTime:4
});
let isRun=true
// 计算当前目标文字
const currentText = computed(() => texts[state.currentTextIndex]);

// 异步等待函数
function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// 动画主逻辑
async function animateText() {
  const targetText = currentText.value;

  if (state.isIncreasing) {
    // 增加文字
    if (state.displayedText.length < targetText.length) {
      state.displayedText = targetText.slice(0, state.displayedText.length + 1);
    } else {
      // 显示完整文字后停留 5 秒
      isRun=false
      state.isIncreasing = false;
      await wait(1000*state.waitTime); // 停留 5 秒
      isRun=true
    }
  } else {
    // 减少文字
    if (state.displayedText.length > 0) {
      state.displayedText = targetText.slice(0, state.displayedText.length - 1);
    } else {
      // 切换到下一个文字
      state.isIncreasing = true;
      state.currentTextIndex = (state.currentTextIndex + 1) % texts.length;
    }
  }
}

let intervalId: number | undefined = undefined;
// 提示框
const showToast=(index:number)=>{
  if(index>0){
    ElMessage({
      message: '页面待完善',
      type: 'warning',
      duration: 2000,
    });
  }
}
//点击跳转
const goConsultingService=()=>{
  window.open('https://3xg.3amao.com/', '_blank')
  // window.location.href='https://3xg.3amao.com/'
}
onMounted(() => {
  nextTick().then(() => {
    intervalId = setInterval(()=>{
      if(isRun){
        animateText()
      }
    }, 100); // 每 100ms 更新一次
  })

});

// 清理定时器
const clearAnimateInterval = () => {
  if (intervalId !== undefined) {
    clearInterval(intervalId);
    intervalId = undefined; // 避免重复清理
  }
};

onUnmounted(() => {
  clearAnimateInterval();
});
</script>

<style scoped lang="scss">
page{
  font-family: PingFangMedium;
}
.topTypeListContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 40px;
  box-sizing: border-box;
  font-family: PingFangMedium;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  .topTypeListTitle{
    display: flex;
    align-items: center;
    margin-left: 170px;
    margin-right: 340px;
    box-sizing: border-box;
    .topTypeListTitleIcon{
      width: 40px;
      height: 40px;
      margin-right: 10px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .topTypeListTitleName{
      font-weight: 500;
      font-size: 18px;
      color: #2D2D2D;
    }
  }
  .topTypeList{
    display: flex;
    align-items: center;
    .checkItem{
      color: #269afe !important;
    }
    .topTypeItem{
      display: flex;
      align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: #333639;
        margin-right: 53px;
        cursor: pointer;
      flex-shrink: 0;
        &:hover{
          color: #269afe;
        }
        &:last-child{
          margin-right: 0;
        }
    }

  }
}
.homeLogoBox{
  width: 100%;
  min-height: 736px;
  background-image: url("@/assets/image/homeTopBackgrouadImg.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  margin-bottom: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  padding-top: 100px;
  font-family: PingFangMedium;
  .homeLogoBoxTitleOne{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    >div:nth-of-type(1){
      font-weight: 500;
      font-size: 45px;
      color: #212024;
    }
    >div:nth-of-type(2){
      font-weight: 400;
      font-size: 45px;
      color: #212024;
    }
  }
  .homeLogoBoxTitleTwo{
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    >div:nth-of-type(1){
      font-weight: 500;
      font-size: 59px;
      color: #212024;
    }
    >div:nth-of-type(2){
      font-weight: 400;
      font-size: 59px;
      color: #FFFFFF;
      line-height: 59px;
      text-align: right;
      font-style: normal;
      /* 应用线性渐变背景 */
      background: linear-gradient(0deg, #4F95FE 0%, #855FF8 100%);
      /* 使用背景剪裁和文本填充颜色属性来使文字透明，显示出背景渐变 */
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .homeButton{
    width: 180px;
    height: 46px;
    background: linear-gradient( 270deg, #B166FF 0%, #6B5DFF 100%);
    box-shadow: 0px 2px 22px 0px rgba(182,178,229,0.48);
    border-radius: 23px;
    border: 2px solid #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: PingFangMedium;
    //margin-bottom: 100px;
  }
  .homeGifBox{

    font-family: PingFangMedium;
    width: 349px;
    //height: 246px;
    background: #FFFFFF;
    //box-shadow: 0px 1px 29px 0px rgba(224,192,198,0.12);
    border-radius: 96px 96px 96px 0px;
    //border: 1px solid;
    border-image: linear-gradient(159deg, rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0.35)) 1 1;
    overflow: hidden;
    margin-left: 450px;
    >img{
      width: 228px;
      margin-top: -50px;
    }
  }
}
.onTrialButton{
  position: fixed;
  right: 12%;
  bottom: 90px;
  width: 114px;
  height: 114px;
  background: linear-gradient(270deg, rgba(177, 102, 255, 0.1) 0%, rgba(107, 93, 255, 0.1) 100%);
  //box-shadow: 0px 2px 22px 0px rgba(182,178,229,0.48);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  //font-family: PingFangMedium;
  //opacity: 0.1;
  >div{
    width: 94px;
    height: 94px;
    background: linear-gradient(270deg, rgba(177, 102, 255, 0.1) 0%, rgba(107, 93, 255, 0.1) 100%);
    //box-shadow: 0px 2px 22px 0px rgba(182,178,229,0.48);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    //opacity: 0.1;
    >div{
      width: 74px;
      height: 74px;
      background: linear-gradient(270deg, rgba(177, 102, 255, 0.6) 0%, rgba(107, 93, 255, 0.6) 100%);
      //box-shadow: 0px 2px 22px 0px rgba(106,97,205,0.31);
      font-weight: 600;
      font-size: 15px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
}
</style>
