import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/image/footIcon.png'


const _hoisted_1 = { class: "footBox" }


export default /*@__PURE__*/_defineComponent({
  __name: 'footBox',
  setup(__props) {

const goConsultingService=()=>{
  window.open('https://3xg.3amao.com/', '_blank')
  // window.location.href='https://3xg.3amao.com/'
}
const goSearch=()=>{
  window.open('https://beian.mps.gov.cn/#/query/webSearch', '_blank')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        _cache[0] || (_cache[0] = _createTextVNode(" 网址: ")),
        _createElementVNode("span", {
          style: {"cursor":"pointer"},
          onClick: goConsultingService
        }, "www.3amao.com")
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", null, " 研发总部:四川省成都市高新区梓州大道领馆国际8号楼 ", -1))
    ]),
    _createElementVNode("div", null, [
      _cache[2] || (_cache[2] = _createElementVNode("div", null, " 版权所有@成都三只阿猫智能科技有限公司 ", -1)),
      _createElementVNode("div", { onClick: goSearch }, " 备案号：蜀ICP备2023012676号 ")
    ])
  ]))
}
}

})