<template>
  <div class="customerMaxBox">
      <div class="customerMaxBoxTitle">
        <div>我们的客户</div>
        <div>以下为部分客户, 排名不分前后</div>
      </div>
      <div class="customerMaxBoxContent">
        <img class="customerMaxBoxContentImg" src="@/assets/image/myCustomerContent.png" alt="">
<!--        <div class="customerMaxBoxContentItem">-->
<!--          <div class="customerMaxBoxContentItemLeft">-->
<!--            互联网-->
<!--          </div>-->
<!--          <div class="customerMaxBoxContentItemRight">-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style scoped lang="scss">
.customerMaxBox{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("@/assets/image/myCustomerBackgrouadImg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top;
  margin-bottom: 100px;
  .customerMaxBoxTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    >div:nth-of-type(1){
      font-weight: 600;
      font-size: 36px;
      color: #292929;
      margin-bottom: 20px;
    }
    >div:nth-of-type(2){
      font-weight: 400;
      font-size: 14px;
      color: #77849B;
    }
  }
  .customerMaxBoxContent{
    display: flex;
    flex-direction: column;

    .customerMaxBoxContentItem{
      width: 1140px;
      //height: 217px;
      background-color: #F6F9FF;
      padding: 20px 32px;
      box-sizing: border-box;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      .customerMaxBoxContentItemLeft{
        width: 162px;
        height: auto;
        background-color: #c5eaff;
        box-shadow: 0 6px 15px 0 rgba(115,202,255,0.3);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 32px;
        color: #0049A8;
      }
      .customerMaxBoxContentItemRight{
        width: 900px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        box-sizing: border-box;
        >div{
          width: 133px;
          height: 64px;
          background: #FFFFFF;
          box-shadow: 0px 2px 10px 0px rgba(203,210,214,0.14);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:nth-of-type(n + 7){
            margin-top: 20px;
          }
        }
      }
    }
  }
  .customerMaxBoxContentImg{
    width: 1140px;
    margin-top: 60px;
  }
}
</style>
