<template>
  <div class="footBox">
    <img src="@/assets/image/footIcon.png"/>
    <div>
      <div>
        网址: <span style="cursor: pointer" @click="goConsultingService">www.3amao.com</span>
      </div>
      <div>
        研发总部:四川省成都市高新区梓州大道领馆国际8号楼
      </div>
    </div>
    <div>
      <div>
        版权所有@成都三只阿猫智能科技有限公司
      </div>
      <div @click="goSearch">
        备案号：蜀ICP备2023012676号
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const goConsultingService=()=>{
  window.open('https://3xg.3amao.com/', '_blank')
  // window.location.href='https://3xg.3amao.com/'
}
const goSearch=()=>{
  window.open('https://beian.mps.gov.cn/#/query/webSearch', '_blank')
}
</script>

<style scoped lang="scss">
.footBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #202231;
  padding-top: 31px;
  >img{
    width: 40px;
    height: 40px;
    margin-bottom: 54px;
  }
  > div:nth-of-type(1) {
    width: 600px;
    font-weight: 400;
    font-size: 12px;
    color: #C3C3C3;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    justify-content: space-between;
  }

  > div:nth-of-type(2) {
    width: 600px;
    font-weight: 400;
    font-size: 11px;
    color: #CBCBCB;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
  }
}
</style>
