import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {onMounted} from "vue";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

onMounted(() => {
  console.log("app mounted")
  console.log(navigator.userAgent)
  if (window.innerWidth <= 767) {
    router.push('/phone')
  } else {
    router.push('/')
  }
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    router.push('/phone')
  } else {
    router.push('/')
  }

})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})