import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/image/homeIcon.png'
import _imports_1 from '@/assets/image/message.gif'


const _hoisted_1 = { class: "topTypeListContainer" }
const _hoisted_2 = { class: "topTypeList" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "AIProductIntroduction" }
const _hoisted_5 = { class: "homeLogoBox" }
const _hoisted_6 = { class: "homeLogoBoxTitleTwo" }

import footBox from "@/components/footBox.vue"
import workingPrinciple from "@/components/workingPrinciple.vue"
import productDetails from "@/components/productDetails.vue";
import myCustomer from "@/components/myCustomer.vue"
import {onMounted, onUnmounted, ref, computed, reactive, nextTick} from 'vue'
import { ElMessage } from 'element-plus'
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

let topTypeList = ref([
  {
    name: "AI产品介绍",
    value:0
  },
  {
    name: "解决方案",
    value:1
  },
  {
    name: "行业情报",
    value:2
  },
  {
    name: "关于我们",
    value:3
  },
])
const texts = ["智能客服", "智能销售", "数字员工","AI智能体"]; // 文字列表

// 使用 reactive 定义状态
const state = reactive<{
  displayedText: string;
  currentTextIndex: number;
  isIncreasing: boolean;
  waitTime:number
}>({
  displayedText: "", // 当前显示的文字
  currentTextIndex: 0, // 当前文字索引
  isIncreasing: true, // 是否正在增加文字
  waitTime:4
});
let isRun=true
// 计算当前目标文字
const currentText = computed(() => texts[state.currentTextIndex]);

// 异步等待函数
function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// 动画主逻辑
async function animateText() {
  const targetText = currentText.value;

  if (state.isIncreasing) {
    // 增加文字
    if (state.displayedText.length < targetText.length) {
      state.displayedText = targetText.slice(0, state.displayedText.length + 1);
    } else {
      // 显示完整文字后停留 5 秒
      isRun=false
      state.isIncreasing = false;
      await wait(1000*state.waitTime); // 停留 5 秒
      isRun=true
    }
  } else {
    // 减少文字
    if (state.displayedText.length > 0) {
      state.displayedText = targetText.slice(0, state.displayedText.length - 1);
    } else {
      // 切换到下一个文字
      state.isIncreasing = true;
      state.currentTextIndex = (state.currentTextIndex + 1) % texts.length;
    }
  }
}

let intervalId: number | undefined = undefined;
// 提示框
const showToast=(index:number)=>{
  if(index>0){
    ElMessage({
      message: '页面待完善',
      type: 'warning',
      duration: 2000,
    });
  }
}
//点击跳转
const goConsultingService=()=>{
  window.open('https://3xg.3amao.com/', '_blank')
  // window.location.href='https://3xg.3amao.com/'
}
onMounted(() => {
  nextTick().then(() => {
    intervalId = setInterval(()=>{
      if(isRun){
        animateText()
      }
    }, 100); // 每 100ms 更新一次
  })

});

// 清理定时器
const clearAnimateInterval = () => {
  if (intervalId !== undefined) {
    clearInterval(intervalId);
    intervalId = undefined; // 避免重复清理
  }
};

onUnmounted(() => {
  clearAnimateInterval();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "topTypeListTitle" }, [
        _createElementVNode("div", { class: "topTypeListTitleIcon" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(topTypeList), (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            onClick: ($event: any) => (showToast(index)),
            class: _normalizeClass(index==0?'topTypeItem checkItem':'topTypeItem'),
            key: index
          }, _toDisplayString(item.name), 11, _hoisted_3))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "homeLogoBoxTitleOne" }, [
          _createElementVNode("div", null, "AI智能体,"),
          _createElementVNode("div", null, "大模型驱动的数字员工")
        ], -1)),
        _createElementVNode("div", _hoisted_6, [
          _cache[1] || (_cache[1] = _createElementVNode("div", null, "他可以是", -1)),
          _createElementVNode("div", null, _toDisplayString(state.displayedText) + "|", 1)
        ]),
        _createElementVNode("div", {
          class: "homeButton",
          onClick: goConsultingService
        }, "立即咨询"),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "homeGifBox" }, [
          _createElementVNode("img", { src: _imports_1 })
        ], -1))
      ]),
      _createVNode(workingPrinciple),
      _createVNode(productDetails),
      _createVNode(myCustomer),
      _createVNode(footBox)
    ]),
    _createElementVNode("div", {
      class: "onTrialButton",
      onClick: goConsultingService
    }, _cache[4] || (_cache[4] = [
      _createElementVNode("div", null, [
        _createElementVNode("div", null, " 立即咨询 ")
      ], -1)
    ]))
  ], 64))
}
}

})