<template>
  <router-view/>
</template>
<script lang="ts" setup>
import {onMounted} from "vue";
import router from "@/router";

onMounted(() => {
  console.log("app mounted")
  console.log(navigator.userAgent)
  if (window.innerWidth <= 767) {
    router.push('/phone')
  } else {
    router.push('/')
  }
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    router.push('/phone')
  } else {
    router.push('/')
  }

})
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
